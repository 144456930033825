<style>
@media (max-width: 1024px) {
  .sm-spacer {
    margin-top: .5rem;
  }
}
</style>
<script>
import {VclBulletList, VclCode, VclFacebook, VclList} from 'vue-content-loading';

import CopyableText from "@/components/CopyableText";
import {mapGetters} from "vuex";
import TimeAgo from "vue2-timeago";
import {Game} from "@/enums";
import {HalfCircleSpinner} from "epic-spinners";
import {get_acsrf_token} from "@/methods";
import {maxLength, minLength, required} from "vuelidate/lib/validators";

export default {
  props: ['cftools_id', 'options'],
  components: {
    CopyableText,
    VclFacebook,
    VclCode,
    VclList,
    VclBulletList,
    TimeAgo,
    HalfCircleSpinner
  },
  computed: {
    ...mapGetters([
      'getUILanguage',
      'getDTLocale'
    ]),
    rows() {
      return this.weapons.length;
    },
  },
  validations: {
    whitelist: {
      comment: {maxLength: maxLength(400)}
    },
    queuepriority: {
      comment: {maxLength: maxLength(400)}
    },
    reservedslot: {
      comment: {maxLength: maxLength(400)}
    }
  },
  methods: {
    timeSince(date) {
      var seconds = Math.floor((new Date() - date) / 1000);
      var interval = seconds / 31536000;
      interval = seconds / 86400;
      if (interval > 1) {
        return Math.floor(interval) + " d";
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return Math.floor(interval) + " h";
      }
      interval = seconds / 60;
      if (interval > 1) {
        return Math.floor(interval) + " m";
      }
      return Math.floor(seconds) + " s";
    },
    currentDateTime(date) {
      if(!date || date === null) date = new Date();
      return `${date.getFullYear()}-${(date.getMonth()+1).pad()}-${date.getDate().pad()}T${date.getHours().pad()}:${date.getMinutes().pad()}`;
    },
    handleError: function(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    getData: function() {
      let url = new URL(process.env.VUE_APP_ROOT_API + `v1/profile/${this.cftools_id}/server`);
      url.searchParams.append('server_id', this.server_id);
      fetch(url, {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          } else {
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then(data => {
          this.server = data;
          if(this.server.omega.game && this.server.omega.game.general) {
            this.dataHitZones = [];
            this.chartHitZones.labels = [];

            if(this.server.omega.game.general.zones) {
              Object.keys(this.server.omega.game.general.zones).forEach((zone) => {
                if (zone === '_total') return;
                let zoneData = this.server.omega.game.general.zones[zone];
                if (zoneData.hits === 0) return;
                this.dataHitZones.push(zoneData.hits);
                this.chartHitZones.labels.push(this.$t(`profile.server.gamestats.zones.${zone}`));
              });
            }

            this.weapons = [];
            if(this.server.omega.game.general.weapons) {
              Object.keys(this.server.omega.game.general.weapons).forEach((weapon) => {
                let weaponData = this.server.omega.game.general.weapons[weapon];
                if (weaponData.hits === 0) return;
                weaponData.weapon = weapon;
                this.weapons.push(weaponData);
              });
            }
            this.totalRows = this.weapons.length;
          }
          this.ready = true;

        })
        .catch(error => {
          this.handleError(error);
        });
    },
    overridePersona(personaName, avatar) {
      this.$parent.$emit('overridePersona', personaName, avatar);
    },
    showIPHistoryModal() {
      this.$refs.ipHistory.show();
    },
    async gotoSettings() {
      await this.$router.push({name: 'server_component', params: {server_id: this.server_id, component: 'settings'}});
    },
    timeTrick(seconds) {
      return new Date(Date.now() - 1000 * seconds);
    },
    supportsQP() {
      let games = [Game.DAYZ];
      return games.includes(this.server.game);
    },
    supportsRS() {
      let games = [];
      return games.includes(this.server.game);
    },
    supportsWL() {
      let games = [Game.DAYZ, Game.ARMA2, Game.ARMA3];
      return games.includes(this.server.game);
    },
    async saveNote() {
      this.inProgress = true;
      await new Promise(resolve => setTimeout(resolve, 500));

      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          server_id: this.server_id,
          note: this.server.notes.data
        };

        let url = new URL(process.env.VUE_APP_ROOT_API + `v1/profile/${this.cftools_id}/note`);
        let response = await fetch(url, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });

        if(response.ok) {
          this.showSuccess = true;
          setTimeout(() => {
            this.showSuccess = false;
          }, 350);
          // this.getData(); // Not needed at this point
        } else {
          if (response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.title'));

          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }

      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }

      this.inProgress = false;
    },
    async wipeNote() {
      this.server.notes.data = null;
      await this.saveNote();
    },
    async updateWLExpiration() {
      if(!this.whitelist.days || this.whitelist.days === '') this.whitelist.days = 0;
      let days = parseInt(this.whitelist.days);
      let date = new Date();
      date.setDate(date.getDate() + days);
      this.whitelist.expiration = this.currentDateTime(date);
    },
    async createWL() {
      this.$v.whitelist.$touch();
      if(this.$v.whitelist.$error) return;

      this.inProgress = true;
      await new Promise(resolve => setTimeout(resolve, 500));

      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          server_id: this.server_id,
          permanent: this.whitelist.permanent,
          comment: this.whitelist.comment,
          expiration: this.whitelist.expiration,
          tz: Intl.DateTimeFormat().resolvedOptions().timeZone
        };

        let url = new URL(process.env.VUE_APP_ROOT_API + `v1/profile/${this.cftools_id}/whitelist`);
        let response = await fetch(url, {
          method: 'PUT',
          body: JSON.stringify(payload),
          credentials: 'include'
        });

        if(response.ok) {
          this.$swal({
            icon: 'success',
            text: this.$t('profile.server.whitelist.add.success')
          });
          this.getData();
        } else {
          if (response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.title'));

          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }

      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }

      this.inProgress = false;
    },
    async removeWL() {
      this.inProgress = true;
      await new Promise(resolve => setTimeout(resolve, 500));

      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          server_id: this.server_id
        };

        let url = new URL(process.env.VUE_APP_ROOT_API + `v1/profile/${this.cftools_id}/whitelist`);
        let response = await fetch(url, {
          method: 'DELETE',
          body: JSON.stringify(payload),
          credentials: 'include'
        });

        if(response.ok) {
          this.$swal({
            icon: 'success',
            text: this.$t('profile.server.whitelist.remove.success')
          });
          this.getData();
        } else {
          if (response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.title'));

          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }

      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }

      this.inProgress = false;
    },
    /* Queue Priority */
    async updateQPExpiration() {
      if(!this.queuepriority.days || this.queuepriority.days === '') this.queuepriority.days = 0;
      let days = parseInt(this.queuepriority.days);
      let date = new Date();
      date.setDate(date.getDate() + days);
      this.queuepriority.expiration = this.currentDateTime(date);
    },
    async createQP() {
      this.$v.queuepriority.$touch();
      if(this.$v.queuepriority.$error) return;

      this.inProgress = true;
      await new Promise(resolve => setTimeout(resolve, 500));

      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          server_id: this.server_id,
          permanent: this.queuepriority.permanent,
          comment: this.queuepriority.comment,
          expiration: this.queuepriority.expiration,
          tz: Intl.DateTimeFormat().resolvedOptions().timeZone
        };

        let url = new URL(process.env.VUE_APP_ROOT_API + `v1/profile/${this.cftools_id}/queuepriority`);
        let response = await fetch(url, {
          method: 'PUT',
          body: JSON.stringify(payload),
          credentials: 'include'
        });

        if(response.ok) {
          this.$swal({
            icon: 'success',
            text: this.$t('profile.server.queuepriority.add.success')
          });
          this.getData();
        } else {
          if (response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.title'));

          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }

      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }

      this.inProgress = false;
    },
    async removeQP() {
      this.inProgress = true;
      await new Promise(resolve => setTimeout(resolve, 500));

      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          server_id: this.server_id
        };

        let url = new URL(process.env.VUE_APP_ROOT_API + `v1/profile/${this.cftools_id}/queuepriority`);
        let response = await fetch(url, {
          method: 'DELETE',
          body: JSON.stringify(payload),
          credentials: 'include'
        });

        if(response.ok) {
          this.$swal({
            icon: 'success',
            text: this.$t('profile.server.queuepriority.remove.success')
          });
          this.getData();
        } else {
          if (response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.title'));

          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }

      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }

      this.inProgress = false;
    },
    /* Reserved Slot */
    async updateRSExpiration() {
      if(!this.reservedslot.days || this.reservedslot.days === '') this.reservedslot.days = 0;
      let days = parseInt(this.reservedslot.days);
      let date = new Date();
      date.setDate(date.getDate() + days);
      this.reservedslot.expiration = this.currentDateTime(date);
    },
    async createRS() {
      this.$v.reservedslot.$touch();
      if(this.$v.reservedslot.$error) return;

      this.inProgress = true;
      await new Promise(resolve => setTimeout(resolve, 500));

      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          server_id: this.server_id,
          permanent: this.reservedslot.permanent,
          comment: this.reservedslot.comment,
          expiration: this.reservedslot.expiration,
          tz: Intl.DateTimeFormat().resolvedOptions().timeZone
        };

        let url = new URL(process.env.VUE_APP_ROOT_API + `v1/profile/${this.cftools_id}/reservedslot`);
        let response = await fetch(url, {
          method: 'PUT',
          body: JSON.stringify(payload),
          credentials: 'include'
        });

        if(response.ok) {
          this.$swal({
            icon: 'success',
            text: this.$t('profile.server.reservedslot.add.success')
          });
          this.getData();
        } else {
          if (response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.title'));

          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }

      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }

      this.inProgress = false;
    },
    async removeRS() {
      this.inProgress = true;
      await new Promise(resolve => setTimeout(resolve, 500));

      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          server_id: this.server_id
        };

        let url = new URL(process.env.VUE_APP_ROOT_API + `v1/profile/${this.cftools_id}/reservedslot`);
        let response = await fetch(url, {
          method: 'DELETE',
          body: JSON.stringify(payload),
          credentials: 'include'
        });

        if(response.ok) {
          this.$swal({
            icon: 'success',
            text: this.$t('profile.server.reservedslot.remove.success')
          });
          this.getData();
        } else {
          if (response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.title'));

          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }

      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }

      this.inProgress = false;
    },
    async resetStats() {
      this.inProgress = true;
      await new Promise(resolve => setTimeout(resolve, 500));

      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          server_id: this.server_id
        };

        let url = new URL(process.env.VUE_APP_ROOT_API + `v1/profile/${this.cftools_id}/stats`);
        let response = await fetch(url, {
          method: 'DELETE',
          body: JSON.stringify(payload),
          credentials: 'include'
        });

        if(response.ok) {
          this.$swal({
            icon: 'success',
            text: this.$t('profile.server.gamestats.reset.completed')
          });
          this.getData();
        } else {
          if (response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.title'));

          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }

      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }

      this.inProgress = false;
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  },
  created() {
    this.server_id = this.options.server_id;
  },
  mounted() {
    this.getData();
    this.$nextTick(() => {
      this.updateWLExpiration();
      this.updateQPExpiration();
      this.updateRSExpiration();
    });
  },
  data() {
    return {
      Game:Game,
      ready: false,
      error: false,
      server_id: null,
      server: null,
      inProgress: false,
      showSuccess: false,
      whitelist: {
        permanent: true,
        expiration: this.currentDateTime(),
        comment: null,
        days: 1
      },
      queuepriority: {
        permanent: true,
        expiration: this.currentDateTime(),
        comment: null,
        days: 1
      },
      reservedslot: {
        permanent: true,
        expiration: this.currentDateTime(),
        comment: null,
        days: 1
      },
      chartHitZones: {
        chart: {
          height: '100%',
          width: '100%',
          type: 'pie',
        },
        labels: [],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              height: 200,
            },
            legend: {
              position: 'bottom'
            }
          }
        }],
        colors: [
            '#4285F4',
            '#564ab1',
            '#e83e8c',
            '#f46a6a',
            '#f1b44c',
            '#34c38f',
            '#50a5f1',
            '#c3cbe4',
            '#32394e',
            '#eff2f7',
            '#fff'
        ]
      },
      dataHitZones: [],
      weapons: [],
      weaponDetails: [
        {
          key: "weapon",
          sortable: true,
          label: this.$t('profile.server.gamestats.weapons.weapon')
        },
        {
          key: "kills",
          sortable: true,
          label: this.$t('profile.server.gamestats.weapons.kills')
        },
        {
          key: "hits",
          sortable: true,
          label: this.$t('profile.server.gamestats.weapons.hits')
        },
        {
          key: "longest_shot",
          sortable: true,
          label: this.$t('profile.server.gamestats.weapons.longest_shot')
        },
        {
          key: "longest_kill",
          sortable: true,
          label: this.$t('profile.server.gamestats.weapons.longest_kill')
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      filter: null,
      filterOn: [],
      sortBy: "kills",
      sortDesc: false,
    }
  }
};
</script>

<template>
  <div>
    <template v-if="ready">
      <b-modal
          ref="ipHistory"
          size="md"
          :title="$t('profile.server.iphistory.title')"
          title-class="font-18"
          hide-footer
          v-if="server.omega.status === true"
      >
        <template v-if="server.omega.general.ipv4_history !== false">
          <table class="table table-nowrap mb-0">
            <tbody>
              <tr v-for="ipinfo in server.omega.general.ipv4_history" :key="ipinfo.ipv4">
                <th scope="row">
                  <CopyableText :text="ipinfo.ipv4" />
                </th>
                <td>
                  <template v-if="(ipinfo.country_code === null && ipinfo.provider === null && ipinfo.malicious === null)">
                    <span class="badge badge-danger">
                      {{ $t('profile.server.iphistory.nodata') }}
                    </span>
                  </template>
                  <template v-else>
                    <flag :iso="ipinfo.country_code" v-if="ipinfo.country_code !== null" />
                    <span v-if="ipinfo.provider !== null">
                      {{ ipinfo.provider }}
                    </span>
                    <span class="badge badge-warning text-black font-size-13 ml-1" v-if="ipinfo.malicious === true" v-b-tooltip.hover :title="$t('profile.server.iphistory.malicious')">
                      <i class="far fa-shield-alt" />
                      {{ $t('profile.server.iphistory.warning') }}
                    </span>
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
        </template>
        <template v-else>
          <div class="alert alert-danger text-center">
            {{ $t('profile.server.iphistory.permissions') }}
          </div>
        </template>
      </b-modal>
      <div class="row">
        <div class="col-lg-12 col-sm-12">
          <!-- Content row one -->
          <div class="row" v-if="server.omega.status === true">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-4 col-sm-12">
                      <div class="row">
                        <div class="col">
                          <h3 class="mb-0">
                            {{ server.omega.general.name_history[server.omega.general.name_history.length - 1] }}
                          </h3>
                          <h5 class="mb-0">
                            {{ $t('profile.server.aliases') }}: {{ server.omega.general.name_history.join(', ') }}
                          </h5>
                          <div class="mt-1">
                            <button class="btn btn-primary btn-rounded btn-sm" v-on:click="showIPHistoryModal()">
                              {{ $t('profile.server.iphistory.button') }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-sm-12 sm-spacer">
                      <div class="row mt-2">
                        <div class="col-6">
                          <h5 class="mb-0">
                            {{ $t('profile.server.playtime') }}
                          </h5>
                        </div>
                        <div class="col-6">
                          <h5 class="mb-0">
                            <!-- <time-ago :refresh="0" :datetime="timeTrick(server.omega.general.playtime)" long :locale="getUILanguage()" /> -->
                            {{ timeSince(timeTrick(server.omega.general.playtime)) }}
                          </h5>
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col-6">
                          <h5 class="mb-0">
                            {{ $t('profile.server.sessions') }}
                          </h5>
                        </div>
                        <div class="col-6">
                          <h5 class="mb-0">
                            {{ server.omega.general.sessions }}
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-sm-12 sm-spacer">
                      <div class="h-100">
                        <div class="row ">
                          <div class="col">
                            <h5 class="text-uppercase d-inline">
                              {{$t('profile.server.omega.created_at')}}
                            </h5>
                            <h4 class="ml-2 d-inline">
                              {{ $d(parseDate(server.omega.created_at), 'date', getDTLocale()) }}
                            </h4>
                          </div>
                        </div>
                        <hr>
                        <div class="row">
                          <div class="col">
                            <h5 class="text-uppercase d-inline">
                              {{$t('profile.server.omega.updated_at')}}
                            </h5>
                            <h4 class="ml-2 d-inline">
                              {{ $d(parseDate(server.omega.updated_at), 'date' , getDTLocale()) }}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-else>
            <div class="col-lg-12 col-sm-12">
              <div class="alert alert-info font-size-18">
                <i class="fad fa-info-circle"/>
                {{$t('profile.server.none.title')}}
                <small class="ml-2">
                  {{$t('profile.server.none.description')}}
                </small>
              </div>
            </div>
          </div>

          <!-- Game stats -->
          <div class="row" v-if="server.omega.status && Object.keys(server.omega.game).length && server.omega.game.general">
            <div class="col-lg-4 col-sm-12">
              <div class="card card-body">
                <h4>
                  {{ $t('profile.server.gamestats.generic.title') }}
                  <button class="btn btn-danger btn-sm float-right" v-if="server.permissions['stats-wipe']" :class="{'disabled': inProgress}" v-on:click="resetStats()">
                    <half-circle-spinner
                        v-if="inProgress"
                        :animation-duration="1200"
                        :size="16"
                        class="align-middle d-inline-block"
                    />
                    <template v-if="!inProgress">
                      <i class="fas fa-trash-alt" />
                    </template>
                  </button>
                </h4>
                <div class="table-responsive mb-0">
                  <table class="table table-nowrap table-centered">
                    <tbody class="w-100">
                    <tr>
                      <td>
                        <b class="text-uppercase text-muted small">{{ $t('profile.server.gamestats.generic.kd') }}</b>
                        <b>
                          {{ $n(server.omega.game.general.kdratio) }}
                        </b>
                        <span class="ml-1">
                          ({{ $n(server.omega.game.general.kills || 0) }}  / {{ $n(server.omega.game.general.deaths || 0) }})
                        </span>
                      </td>
                      <td>
                        <b class="text-uppercase text-muted small">{{ $t('profile.server.gamestats.generic.suicides') }}</b>
                        <b>
                          {{ $n(server.omega.game.general.suicides || 0) }}
                        </b>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b class="text-uppercase text-muted small">{{ $t('profile.server.gamestats.generic.infected_deaths') }}</b>
                        <b>
                          {{ $n(server.omega.game.general.infected_deaths || 0) }}
                        </b>
                      </td>
                      <td>
                        <b class="text-uppercase text-muted small">{{ $t('profile.server.gamestats.generic.environment_deaths') }}</b>
                        <b>
                          {{ $n(server.omega.game.general.environment_deaths || 0) }}
                        </b>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b class="text-uppercase text-muted small">{{ $t('profile.server.gamestats.generic.longest_shot') }}</b>
                        <b>
                          {{ $n(server.omega.game.general.longest_shot || 0) }}m
                        </b>
                      </td>
                      <td>
                        <b class="text-uppercase text-muted small">{{ $t('profile.server.gamestats.generic.longest_kill') }}</b>
                        <b>
                          {{ $n(server.omega.game.general.longest_kill || 0) }}m
                        </b>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <button class="btn btn-primary btn-block" v-b-modal.weaponModal>
                          {{ $t('profile.server.gamestats.generic.weapon_stats') }}
                        </button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <b-modal
                    id="weaponModal"
                    ref="weaponModal"
                    size="lg"
                    :title="$t('profile.server.gamestats.generic.weapon_stats')"
                    title-class="font-18"
                    hide-footer
                >
                  <div class="row">
                    <!-- Search -->
                    <div class="col-sm-12 col-md-6">
                      <div
                          id="tickets-table_filter"
                          class="dataTables_filter text-md-end"
                      >
                        <label class="d-inline-flex align-items-center">
                          Search
                          <b-form-input
                              v-model="filter"
                              type="search"
                              class="form-control form-control-sm ms-2 ml-1"
                          ></b-form-input>
                        </label>
                      </div>
                    </div>
                    <!-- End search -->
                  </div>
                  <!-- Table -->
                  <div class="table-responsive mb-0">
                    <b-table
                        class="datatables"
                        :items="weapons"
                        :fields="weaponDetails"
                        responsive="sm"
                        :per-page="10"
                        :current-page="currentPage"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        @filtered="onFiltered"
                    ></b-table>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div
                          class="dataTables_paginate paging_simple_numbers float-end"
                      >
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <b-pagination
                              v-model="currentPage"
                              :total-rows="rows"
                              :per-page="10"
                          ></b-pagination>
                        </ul>
                      </div>
                    </div>
                  </div>
                </b-modal>
              </div>
            </div>
            <div class="col-lg-8 col-sm-12" v-if="server.omega.game.general && server.omega.game.general.zones">
              <div class="card card-body">
                <div class="row">
                  <div class="col-lg-6 col-sm-12">
                    <h4>
                      {{ $t('profile.server.gamestats.zones.title') }}
                    </h4>
                    <div class="table-responsive mb-0">
                      <table class="table table-nowrap table-centered">
                        <tbody class="w-100">
                        <tr>
                          <td>
                            <b class="text-uppercase text-muted small">{{ $t('profile.server.gamestats.zones.head') }}</b>
                            <b>
                              {{ server.omega.game.general.zones.head.rate*100 }} %
                            </b>
                            <span>
                          ({{ server.omega.game.general.zones.head.hits }} {{ $t('profile.server.gamestats.zones.hits') }})
                        </span>
                          </td>
                          <td>
                            <b class="text-uppercase text-muted small">{{ $t('profile.server.gamestats.zones.brain') }}</b>
                            <b>
                              {{ server.omega.game.general.zones.brain.rate*100 }} %
                            </b>
                            <span>
                          ({{ server.omega.game.general.zones.brain.hits }} {{ $t('profile.server.gamestats.zones.hits') }})
                        </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b class="text-uppercase text-muted small">{{ $t('profile.server.gamestats.zones.torso') }}</b>
                            <b>
                              {{ server.omega.game.general.zones.torso.rate*100 }} %
                            </b>
                            <span>
                          ({{ server.omega.game.general.zones.torso.hits }} {{ $t('profile.server.gamestats.zones.hits') }})
                        </span>
                          </td>
                          <td>
                            <b class="text-uppercase text-muted small">{{ $t('profile.server.gamestats.zones.spine') }}</b>
                            <b>
                              {{ server.omega.game.general.zones.spine.rate*100 }} %
                            </b>
                            <span>
                          ({{ server.omega.game.general.zones.spine.hits }} {{ $t('profile.server.gamestats.zones.hits') }})
                        </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b class="text-uppercase text-muted small">{{ $t('profile.server.gamestats.zones.liver') }}</b>
                            <b>
                              {{ server.omega.game.general.zones.liver.rate*100 }} %
                            </b>
                            <span>
                          ({{ server.omega.game.general.zones.liver.hits }} {{ $t('profile.server.gamestats.zones.hits') }})
                        </span>
                          </td>
                          <td>
                            <b class="text-uppercase text-muted small">{{ $t('profile.server.gamestats.zones.heart') }}</b>
                            <b>
                              {{ server.omega.game.general.zones.heart.rate*100 }} %
                            </b>
                            <span>
                          ({{ server.omega.game.general.zones.heart.hits }} {{ $t('profile.server.gamestats.zones.hits') }})
                        </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b class="text-uppercase text-muted small">{{ $t('profile.server.gamestats.zones.leftarm') }}</b>
                            <b>
                              {{ server.omega.game.general.zones.leftarm.rate*100 }} %
                            </b>
                            <span>
                          ({{ server.omega.game.general.zones.leftarm.hits }} {{ $t('profile.server.gamestats.zones.hits') }})
                        </span>
                          </td>
                          <td>
                            <b class="text-uppercase text-muted small">{{ $t('profile.server.gamestats.zones.rightarm') }}</b>
                            <b>
                              {{ server.omega.game.general.zones.rightarm.rate*100 }} %
                            </b>
                            <span>
                          ({{ server.omega.game.general.zones.rightarm.hits }} {{ $t('profile.server.gamestats.zones.hits') }})
                        </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b class="text-uppercase text-muted small">{{ $t('profile.server.gamestats.zones.leftleg') }}</b>
                            <b>
                              {{ server.omega.game.general.zones.leftleg.rate*100 }} %
                            </b>
                            <span>
                          ({{ server.omega.game.general.zones.leftleg.hits }} {{ $t('profile.server.gamestats.zones.hits') }})
                        </span>
                          </td>
                          <td>
                            <b class="text-uppercase text-muted small">{{ $t('profile.server.gamestats.zones.rightleg') }}</b>
                            <b>
                              {{ server.omega.game.general.zones.rightleg.rate*100 }} %
                            </b>
                            <span>
                          ({{ server.omega.game.general.zones.rightleg.hits }} {{ $t('profile.server.gamestats.zones.hits') }})
                        </span>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-12">
                    <div id="chart">
                      <apexchart type="pie" :options="chartHitZones" :series="dataHitZones"></apexchart>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-else-if="server.omega.status">
            <div class="col">
              <div class="alert alert-info text-center">
                <i class="fad fa-info-circle" />
                {{ $t('profile.server.gamestats.none') }}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3 text-muted">
                    <b-tab active>
                      <template v-slot:title>
                        <span class="d-inline-block d-sm-none">
                          <i class="fas fa-sticky-note" />
                        </span>
                        <span class="d-none d-sm-inline-block">
                          {{$t('profile.server.note.title')}}
                        </span>
                      </template>
                      <template v-if="server.notes.setup_required === true">
                        <div class="row">
                          <div class="col-lg-12 col-sm-12 justify-content-center">
                            <div class="alert alert-primary font-size-16 mb-0 text-center">
                              <i class="fad fa-cogs" />
                              {{ $t('profile.server.feature.unavailable.setup.title') }}
                              <button class="btn btn-info btn-sm" v-on:click="gotoSettings()">
                                {{ $t('profile.server.feature.unavailable.setup.button') }}
                              </button>
                            </div>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div class="row">
                          <div class="col-lg-12 col-sm-12">
                            <textarea
                                v-model="server.notes.data"
                                type="text"
                                class="form-control"
                                :placeholder="$t('profile.server.note.placeholder')"
                                rows="5"
                                :class="{
                                  'is-valid': showSuccess,
                                  'disabled': server.notes.permissions.edit === false && server.notes.status === true && server.notes.data.length
                                }"
                            />
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-lg-12 col-sm-12 text-center">
                            <button class="btn btn-primary" v-on:click="saveNote()" :class="{'disabled': inProgress}" v-if="server.notes.permissions.edit === true || server.notes.permissions.add">
                              <half-circle-spinner
                                  v-if="inProgress"
                                  :animation-duration="1200"
                                  :size="16"
                                  class="align-middle d-inline-block"
                              />
                              <template v-if="!inProgress">
                                {{ $t('profile.server.note.save') }}
                              </template>
                            </button>
                            <button class="btn btn-danger float-right" v-on:click="wipeNote()" :class="{'disabled': inProgress}" v-if="server.notes.permissions.remove">
                              <half-circle-spinner
                                  v-if="inProgress"
                                  :animation-duration="1200"
                                  :size="16"
                                  class="align-middle d-inline-block"
                              />
                              <template v-if="!inProgress">
                                <i class="fas fa-trash-alt" />
                              </template>
                            </button>
                          </div>
                        </div>
                      </template>
                    </b-tab>
                    <b-tab>
                      <template v-slot:title>
                        <span class="d-inline-block d-sm-none">
                          <i class="far fa-clipboard-list-check"></i>
                        </span>
                        <span class="d-none d-sm-inline-block">
                          {{$t('profile.server.whitelist.title')}}
                        </span>
                      </template>
                      <template v-if="supportsWL()">
                        <template v-if="server.whitelist.setup_required === true">
                          <div class="row">
                            <div class="col-lg-12 col-sm-12 justify-content-center">
                              <div class="alert alert-primary font-size-16 mb-0 text-center">
                                <i class="fad fa-cogs" />
                                {{ $t('profile.server.feature.unavailable.setup.title') }}
                                <button class="btn btn-info btn-sm" v-on:click="gotoSettings()">
                                  {{ $t('profile.server.feature.unavailable.setup.button') }}
                                </button>
                              </div>
                            </div>
                          </div>
                        </template>
                        <template v-else>
                          <template v-if="server.whitelist.status">
                            <div class="row justify-content-center">
                              <div class="col-lg-6 col-sm-12">
                                <div class="row">
                                  <div class="col">
                                    <div class="table-responsive">
                                      <table class="table table-nowrap mb-0">
                                        <tbody>
                                        <tr>
                                          <th scope="row">
                                            <h6 class="text-uppercase mb-0">{{ $t('profile.server.whitelist.created_at') }}</h6>
                                          </th>
                                          <td>
                                            {{ $d(parseDate(server.whitelist.created_at), 'datetime', getDTLocale()) }},
                                            <time-ago  :refresh="60" :datetime="parseDate(server.whitelist.created_at)" long :locale="getUILanguage()"></time-ago>
                                          </td>
                                        </tr>
                                        <tr>
                                          <th scope="row">
                                            <h6 class="text-uppercase mb-0">{{ $t('profile.server.whitelist.issuer') }}</h6>
                                          </th>
                                          <td v-if="server.whitelist.created_by.cftools_id !== null">
                                            <router-link target="_blank" :to="{name: 'profile', params: {cftools_id: server.whitelist.created_by.cftools_id}}">
                                              <img
                                                  :src="server.whitelist.created_by.avatar"
                                                  alt="profile picture"
                                                  class="rounded-circle avatar-xs"
                                                  v-if="server.whitelist.created_by.avatar"
                                              >
                                              <template v-if="server.whitelist.created_by.display_name">
                                                {{ server.whitelist.created_by.display_name }}
                                              </template>
                                              <template v-else>
                                                {{ server.whitelist.created_by.cftools_id }}
                                              </template>
                                            </router-link>
                                          </td>
                                          <td v-else>
                                            <h5 class="text-code mb-0 text-info">
                                              <i class="fal fa-info-circle" />
                                              {{ $t('server.view.integrations.webhooks.list.integration')}}
                                            </h5>
                                          </td>
                                        </tr>
                                        <tr>
                                          <th scope="row">
                                            <h6 class="text-uppercase mb-0">{{ $t('profile.server.whitelist.expires_at') }}</h6>
                                          </th>
                                          <td v-if="server.whitelist.expires_at !== null">
                                            <template v-if="server.whitelist.expired">
                                              <div class="badge badge-warning text-black">
                                                {{ $t('profile.server.whitelist.expired') }}
                                              </div>
                                              <small>
                                                {{ $t('profile.server.whitelist.expiredinfo') }}
                                              </small>
                                            </template>
                                            <template v-else>
                                              {{ $d(parseDate(server.whitelist.expires_at), 'datetime', getDTLocale()) }}
                                            </template>
                                          </td>
                                          <td v-else>
                                            {{ $t('profile.server.whitelist.never') }}
                                          </td>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                                <div class="row" v-if="server.whitelist.comment !== null && server.whitelist.comment.length">
                                  <div class="col">
                                    <div class="form-group">
                                      <textarea
                                          v-text="server.whitelist.comment"
                                          type="text"
                                          class="form-control"
                                          readonly="readonly"
                                          rows="4"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="row" v-if="server.whitelist.permissions.remove === true">
                                  <div class="col">
                                    <button class="btn btn-warning btn-block text-black" v-on:click="removeWL()" :class="{'disabled': inProgress}">
                                      <half-circle-spinner
                                          v-if="inProgress"
                                          :animation-duration="1200"
                                          :size="16"
                                          class="align-middle d-inline-block"
                                      />
                                      <template v-if="!inProgress">
                                        {{ $t('profile.server.whitelist.remove.button') }}
                                      </template>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </template>
                          <!-- No whitelist entry -->
                          <template v-else>
                            <!-- Can create new entry -->
                            <template v-if="server.whitelist.permissions.add === true">
                              <div class="row justify-content-center">
                                <div class="col-lg-5 col-sm-12">
                                  <h3 class="">
                                    {{ $t('profile.server.whitelist.prompt') }}
                                  </h3>
                                  <div class="row">
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="whitelistComment">
                                          {{ $t('profile.server.whitelist.comment.label') }}
                                        </label>
                                        <textarea
                                            id="whitelistComment"
                                            v-model="whitelist.comment"
                                            type="text"
                                            class="form-control"
                                            :placeholder="$t('profile.server.whitelist.comment.placeholder')"
                                            :class="{ 'is-invalid': $v.whitelist.comment.$error }"
                                            rows="4"
                                        />
                                        <div v-if="$v.whitelist.comment.$error" class="invalid-feedback">
                                          <span v-if="!$v.whitelist.comment.maxLength">{{ $t('banmanager.add.details.description.maxlength') }}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-lg-4 col-sm-12">
                                      <div class="justify-content-center text-center">
                                        <b-form-checkbox
                                            switch
                                            v-model="whitelist.permanent"
                                            class="mt-lg-2"
                                        >
                                          <template v-if="whitelist.permanent">
                                            {{ $t('profile.server.whitelist.permanent') }}
                                          </template>
                                          <template v-else>
                                            {{ $t('profile.server.whitelist.temporary') }}
                                          </template>
                                        </b-form-checkbox>
                                      </div>
                                    </div>
                                    <div class="col-lg-8 col-sm-12">
                                      <div class="row" v-if="!whitelist.permanent">
                                        <div class="col-lg-7">
                                          {{ $t('profile.server.whitelist.date') }}
                                          <b-form-input
                                                        id="date-time"
                                                        v-model="whitelist.expiration"
                                                        type="datetime-local"
                                                        :disabled="whitelist.permanent"
                                          />
                                        </div>
                                        <div class="col-lg-5">
                                          {{ $t('profile.server.whitelist.days') }}
                                          <b-form-input
                                              v-model="whitelist.days"
                                              type="number"
                                              :disabled="whitelist.permanent"
                                              v-on:input="updateWLExpiration()"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row mt-4">
                                    <div class="col">
                                      <button class="btn btn-primary btn-block" v-on:click="createWL()" :class="{'disabled': inProgress}" v-if="server.whitelist.permissions.add">
                                        <half-circle-spinner
                                            v-if="inProgress"
                                            :animation-duration="1200"
                                            :size="16"
                                            class="align-middle d-inline-block"
                                        />
                                        <template v-if="!inProgress">
                                          {{ $t('profile.server.whitelist.add.button') }}
                                        </template>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </template>
                            <!-- Can not create new entry -->
                            <template v-else>
                              <div class="row">
                                <div class="col-lg-12 col-sm-12 justify-content-center">
                                  <div class="alert alert-danger font-size-16 mb-0 text-center">
                                    <i class="fas fa-do-not-enter" />
                                    {{ $t('profile.server.whitelist.permissions') }}
                                  </div>
                                </div>
                              </div>
                            </template>
                          </template>
                        </template>
                      </template>
                      <template v-else>
                        <div class="row">
                          <div class="col-lg-12 col-sm-12 justify-content-center">
                            <div class="alert alert-warning font-size-16 mb-0 text-center">
                              {{ $t('profile.server.feature.unavailable.game') }}
                            </div>
                          </div>
                        </div>
                      </template>
                    </b-tab>
                    <b-tab>
                      <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="far fa-star"></i>
                    </span>
                        <span class="d-none d-sm-inline-block">
                          {{$t('profile.server.reservedslot.title')}}
                        </span>
                      </template>
                      <template v-if="supportsRS()">
                        <template v-if="server.reserved_slot.setup_required === true">
                          <div class="row">
                            <div class="col-lg-12 col-sm-12 justify-content-center">
                              <div class="alert alert-primary font-size-16 mb-0 text-center">
                                <i class="fad fa-cogs" />
                                {{ $t('profile.server.feature.unavailable.setup.title') }}
                                <button class="btn btn-info btn-sm" v-on:click="gotoSettings()">
                                  {{ $t('profile.server.feature.unavailable.setup.button') }}
                                </button>
                              </div>
                            </div>
                          </div>
                        </template>
                        <template v-else>
                          <template v-if="server.reserved_slot.status">
                            <div class="row justify-content-center">
                              <div class="col-lg-5 col-sm-12">
                                <div class="row">
                                  <div class="col">
                                    <div class="table-responsive">
                                      <table class="table table-nowrap mb-0">
                                        <tbody>
                                        <tr>
                                          <th scope="row">
                                            <h6 class="text-uppercase mb-0">{{ $t('profile.server.reservedslot.created_at') }}</h6>
                                          </th>
                                          <td>
                                            {{ $d(parseDate(server.reserved_slot.created_at), 'datetime', getDTLocale()) }},<time-ago  :refresh="60" :datetime="parseDate(server.reserved_slot.created_at)" long :locale="getUILanguage()"></time-ago>
                                          </td>
                                        </tr>
                                        <tr>
                                          <th scope="row">
                                            <h6 class="text-uppercase mb-0">{{ $t('profile.server.reservedslot.issuer') }}</h6>
                                          </th>
                                          <td v-if="server.reserved_slot.created_by.cftools_id !== null">
                                            <router-link target="_blank" :to="{name: 'profile', params: {cftools_id: server.reserved_slot.created_by.cftools_id}}">
                                              <img
                                                  :src="server.reserved_slot.created_by.avatar"
                                                  alt="profile picture"
                                                  class="rounded-circle avatar-xs"
                                                  v-if="server.reserved_slot.created_by.avatar"
                                              >
                                              <template v-if="server.reserved_slot.created_by.display_name">
                                                {{ server.reserved_slot.created_by.display_name }}
                                              </template>
                                              <template v-else>
                                                {{ server.reserved_slot.created_by.cftools_id }}
                                              </template>
                                            </router-link>
                                          </td>
                                          <td v-else>
                                            <h5 class="text-code mb-0 text-info">
                                              <i class="fal fa-info-circle" />
                                              {{ $t('server.view.integrations.webhooks.list.integration')}}
                                            </h5>
                                          </td>
                                        </tr>
                                        <tr>
                                          <th scope="row">
                                            <h6 class="text-uppercase mb-0">{{ $t('profile.server.reservedslot.expires_at') }}</h6>
                                          </th>
                                          <td v-if="server.reserved_slot.expires_at !== null">
                                            <template v-if="server.reserved_slot.expired">
                                              <div class="badge badge-warning text-black">
                                                {{ $t('profile.server.reservedslot.expired') }}
                                              </div>
                                              <small>
                                                {{ $t('profile.server.reservedslot.expiredinfo') }}
                                              </small>
                                            </template>
                                            <template v-else>
                                              {{ $d(parseDate(server.reserved_slot.expires_at), 'datetime', getDTLocale()) }}
                                            </template>
                                          </td>
                                          <td v-else>
                                            {{ $t('profile.server.reservedslot.never') }}
                                          </td>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                                <div class="row" v-if="server.reserved_slot.comment !== null && server.reserved_slot.comment.length">
                                  <div class="col">
                                    <div class="form-group">
                                      <textarea
                                          v-text="server.reserved_slot.comment"
                                          type="text"
                                          class="form-control"
                                          readonly="readonly"
                                          rows="4"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="row" v-if="server.reserved_slot.permissions.remove === true">
                                  <div class="col">
                                    <button class="btn btn-warning btn-block text-black" v-on:click="removeRS()" :class="{'disabled': inProgress}">
                                      <half-circle-spinner
                                          v-if="inProgress"
                                          :animation-duration="1200"
                                          :size="16"
                                          class="align-middle d-inline-block"
                                      />
                                      <template v-if="!inProgress">
                                        {{ $t('profile.server.reservedslot.remove.button') }}
                                      </template>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </template>
                          <!-- No reservedslot entry -->
                          <template v-else>
                            <!-- Can create new entry -->
                            <template v-if="server.reserved_slot.permissions.add === true">
                              <div class="row justify-content-center">
                                <div class="col-lg-5 col-sm-12">
                                  <h3 class="">
                                    {{ $t('profile.server.reservedslot.prompt') }}
                                  </h3>
                                  <div class="row">
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="reservedslotComment">
                                          {{ $t('profile.server.reservedslot.comment.label') }}
                                        </label>
                                        <textarea
                                            id="reservedslotComment"
                                            v-model="reservedslot.comment"
                                            type="text"
                                            class="form-control"
                                            :placeholder="$t('profile.server.reservedslot.comment.placeholder')"
                                            :class="{ 'is-invalid': $v.reservedslot.comment.$error }"
                                            rows="4"
                                        />
                                        <div v-if="$v.reservedslot.comment.$error" class="invalid-feedback">
                                          <span v-if="!$v.reservedslot.comment.maxLength">{{ $t('banmanager.add.details.description.maxlength') }}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-lg-4 col-sm-12">
                                      <div class="justify-content-center text-center">
                                        <b-form-checkbox
                                            switch
                                            v-model="reservedslot.permanent"
                                            class="mt-lg-2"
                                        >
                                          <template v-if="reservedslot.permanent">
                                            {{ $t('profile.server.reservedslot.permanent') }}
                                          </template>
                                          <template v-else>
                                            {{ $t('profile.server.reservedslot.temporary') }}
                                          </template>
                                        </b-form-checkbox>
                                      </div>
                                    </div>
                                    <div class="col-lg-8 col-sm-12">
                                      <div class="row" v-if="!reservedslot.permanent">
                                        <div class="col-lg-7">
                                          {{ $t('profile.server.reservedslot.date') }}
                                          <b-form-input
                                              id="date-time"
                                              v-model="reservedslot.expiration"
                                              type="datetime-local"
                                              :disabled="reservedslot.permanent"
                                          />
                                        </div>
                                        <div class="col-lg-5">
                                          {{ $t('profile.server.reservedslot.days') }}
                                          <b-form-input
                                              v-model="reservedslot.days"
                                              type="number"
                                              :disabled="reservedslot.permanent"
                                              v-on:input="updateRSExpiration()"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row mt-4">
                                    <div class="col">
                                      <button class="btn btn-primary btn-block" v-on:click="createRS()" :class="{'disabled': inProgress}" v-if="server.reserved_slot.permissions.add">
                                        <half-circle-spinner
                                            v-if="inProgress"
                                            :animation-duration="1200"
                                            :size="16"
                                            class="align-middle d-inline-block"
                                        />
                                        <template v-if="!inProgress">
                                          {{ $t('profile.server.reservedslot.add.button') }}
                                        </template>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </template>
                            <!-- Can not create new entry -->
                            <template v-else>
                              <div class="row">
                                <div class="col-lg-12 col-sm-12 justify-content-center">
                                  <div class="alert alert-danger font-size-16 mb-0 text-center">
                                    <i class="fas fa-do-not-enter" />
                                    {{ $t('profile.server.reservedslot.permissions') }}
                                  </div>
                                </div>
                              </div>
                            </template>
                          </template>
                        </template>
                      </template>
                      <template v-else>
                        <div class="row">
                          <div class="col-lg-12 col-sm-12 justify-content-center">
                            <div class="alert alert-warning font-size-16 mb-0 text-center">
                              {{ $t('profile.server.feature.unavailable.game') }}
                            </div>
                          </div>
                        </div>
                      </template>
                    </b-tab>
                    <b-tab>
                      <template v-slot:title>
                        <span class="d-inline-block d-sm-none">
                          <i class="fas fa-shipping-fast"></i>
                        </span>
                        <span class="d-none d-sm-inline-block">
                          {{$t('profile.server.queuepriority.title')}}
                        </span>
                      </template>
                      <template v-if="supportsQP()">
                        <template v-if="server.queue_priority.setup_required === true">
                          <div class="row">
                            <div class="col-lg-12 col-sm-12 justify-content-center">
                              <div class="alert alert-primary font-size-16 mb-0 text-center">
                                <i class="fad fa-cogs" />
                                {{ $t('profile.server.feature.unavailable.setup.title') }}
                                <button class="btn btn-info btn-sm" v-on:click="gotoSettings()">
                                  {{ $t('profile.server.feature.unavailable.setup.button') }}
                                </button>
                              </div>
                            </div>
                          </div>
                        </template>
                        <template v-else>
                          <template v-if="server.queue_priority.status">
                            <div class="row justify-content-center">
                              <div class="col-lg-5 col-sm-12">
                                <div class="row">
                                  <div class="col">
                                    <div class="table-responsive">
                                      <table class="table table-nowrap mb-0">
                                        <tbody>
                                        <tr>
                                          <th scope="row">
                                            <h6 class="text-uppercase mb-0">{{ $t('profile.server.queuepriority.created_at') }}</h6>
                                          </th>
                                          <td>
                                            {{ $d(parseDate(server.queue_priority.created_at), 'datetime', getDTLocale()) }},<time-ago  :refresh="60" :datetime="parseDate(server.queue_priority.created_at)" long :locale="getUILanguage()"></time-ago>
                                          </td>
                                        </tr>
                                        <tr>
                                          <th scope="row">
                                            <h6 class="text-uppercase mb-0">{{ $t('profile.server.queuepriority.issuer') }}</h6>
                                          </th>
                                          <td v-if="server.queue_priority.created_by.cftools_id !== null">
                                            <router-link target="_blank" :to="{name: 'profile', params: {cftools_id: server.queue_priority.created_by.cftools_id}}">
                                              <img
                                                  :src="server.queue_priority.created_by.avatar"
                                                  alt="profile picture"
                                                  class="rounded-circle avatar-xs"
                                                  v-if="server.queue_priority.created_by.avatar"
                                              >
                                              <template v-if="server.queue_priority.created_by.display_name">
                                                {{ server.queue_priority.created_by.display_name }}
                                              </template>
                                              <template v-else>
                                                {{ server.queue_priority.created_by.cftools_id }}
                                              </template>
                                            </router-link>
                                          </td>
                                          <td v-else>
                                            <h5 class="text-code mb-0 text-info">
                                              <i class="fal fa-info-circle" />
                                              {{ $t('server.view.integrations.webhooks.list.integration')}}
                                            </h5>
                                          </td>
                                        </tr>
                                        <tr>
                                          <th scope="row">
                                            <h6 class="text-uppercase mb-0">{{ $t('profile.server.queuepriority.expires_at') }}</h6>
                                          </th>
                                          <td v-if="server.queue_priority.expires_at !== null">
                                            <template v-if="server.queue_priority.expired">
                                              <div class="badge badge-warning text-black">
                                                {{ $t('profile.server.queuepriority.expired') }}
                                              </div>
                                              <small>
                                                {{ $t('profile.server.queuepriority.expiredinfo') }}
                                              </small>
                                            </template>
                                            <template v-else>
                                              {{ $d(parseDate(server.queue_priority.expires_at), 'datetime', getDTLocale()) }}
                                            </template>
                                          </td>
                                          <td v-else>
                                            {{ $t('profile.server.queuepriority.never') }}
                                          </td>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                                <div class="row" v-if="server.queue_priority.comment !== null && server.queue_priority.comment.length">
                                  <div class="col">
                                    <div class="form-group">
                                      <textarea
                                          v-text="server.queue_priority.comment"
                                          type="text"
                                          class="form-control"
                                          readonly="readonly"
                                          rows="4"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="row" v-if="server.queue_priority.permissions.remove === true">
                                  <div class="col">
                                    <button class="btn btn-warning btn-block text-black" v-on:click="removeQP()" :class="{'disabled': inProgress}">
                                      <half-circle-spinner
                                          v-if="inProgress"
                                          :animation-duration="1200"
                                          :size="16"
                                          class="align-middle d-inline-block"
                                      />
                                      <template v-if="!inProgress">
                                        {{ $t('profile.server.queuepriority.remove.button') }}
                                      </template>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </template>
                          <!-- No reservedslot entry -->
                          <template v-else>
                            <!-- Can create new entry -->
                            <template v-if="server.queue_priority.permissions.add === true">
                              <div class="row justify-content-center">
                                <div class="col-lg-5 col-sm-12">
                                  <h3 class="">
                                    {{ $t('profile.server.queuepriority.prompt') }}
                                  </h3>
                                  <div class="row">
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="queuepriorityComment">
                                          {{ $t('profile.server.queuepriority.comment.label') }}
                                        </label>
                                        <textarea
                                            id="queuepriorityComment"
                                            v-model="queuepriority.comment"
                                            type="text"
                                            class="form-control"
                                            :placeholder="$t('profile.server.queuepriority.comment.placeholder')"
                                            :class="{ 'is-invalid': $v.queuepriority.comment.$error }"
                                            rows="4"
                                        />
                                        <div v-if="$v.queuepriority.comment.$error" class="invalid-feedback">
                                          <span v-if="!$v.queuepriority.comment.maxLength">{{ $t('banmanager.add.details.description.maxlength') }}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-lg-4 col-sm-12">
                                      <div class="justify-content-center text-center">
                                        <b-form-checkbox
                                            switch
                                            v-model="queuepriority.permanent"
                                            class="mt-lg-2"
                                        >
                                          <template v-if="queuepriority.permanent">
                                            {{ $t('profile.server.queuepriority.permanent') }}
                                          </template>
                                          <template v-else>
                                            {{ $t('profile.server.queuepriority.temporary') }}
                                          </template>
                                        </b-form-checkbox>
                                      </div>
                                    </div>
                                    <div class="col-lg-8 col-sm-12">
                                      <div class="row" v-if="!queuepriority.permanent">
                                        <div class="col-lg-7">
                                          {{ $t('profile.server.queuepriority.date') }}
                                          <b-form-input
                                              id="date-time"
                                              v-model="queuepriority.expiration"
                                              type="datetime-local"
                                              :disabled="queuepriority.permanent"
                                          />
                                        </div>
                                        <div class="col-lg-5">
                                          {{ $t('profile.server.queuepriority.days') }}
                                          <b-form-input
                                              v-model="queuepriority.days"
                                              type="number"
                                              :disabled="queuepriority.permanent"
                                              v-on:input="updateQPExpiration()"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row mt-4">
                                    <div class="col">
                                      <button class="btn btn-primary btn-block" v-on:click="createQP()" :class="{'disabled': inProgress}" v-if="server.queue_priority.permissions.add">
                                        <half-circle-spinner
                                            v-if="inProgress"
                                            :animation-duration="1200"
                                            :size="16"
                                            class="align-middle d-inline-block"
                                        />
                                        <template v-if="!inProgress">
                                          {{ $t('profile.server.queuepriority.add.button') }}
                                        </template>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </template>
                            <!-- Can not create new entry -->
                            <template v-else>
                              <div class="row">
                                <div class="col-lg-12 col-sm-12 justify-content-center">
                                  <div class="alert alert-danger font-size-16 mb-0 text-center">
                                    <i class="fas fa-do-not-enter" />
                                    {{ $t('profile.server.queuepriority.permissions') }}
                                  </div>
                                </div>
                              </div>
                            </template>
                          </template>
                        </template>
                      </template>
                      <template v-else>
                        <div class="row">
                          <div class="col-lg-12 col-sm-12 justify-content-center">
                            <div class="alert alert-warning font-size-16 mb-0 text-center">
                              {{ $t('profile.server.feature.unavailable.game') }}
                            </div>
                          </div>
                        </div>
                      </template>
                    </b-tab>
                  </b-tabs>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <!--
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col">
                      <pre>
{{ JSON.stringify(server.omega.game, null, '\t') }}
                      </pre>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      -->
    </template>
    <template v-else-if="error">
      <div class="row">
        <div class="col-lg-12 col-sm-12">
          <div class="card border border-danger">
            <div class="card-header bg-transparent border-danger">
              <h5 class="my-0 text-danger">
                <i class="far fa-exclamation-circle mr-3"></i>
                {{$t('error.server.generic.title')}}
              </h5>
            </div>
            <div class="card-body pt-0">
              <h5 class="card-title mt-0"> {{$t('error.server.generic.component')}}</h5>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row">
        <div class="col-lg-11">
          <!-- Content row one -->
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <VclFacebook :speed="1" primary="#FFFFFF" secondary="#bdbdbd"></VclFacebook>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="card">
                <div class="card-body">
                  <VclBulletList :speed="1" primary="#FFFFFF" secondary="#bdbdbd"></VclBulletList>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="card">
                <div class="card-body">
                  <VclList :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4 mb-2"></VclList>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
